<template>
    <div class="makros flex">
        <div class="max-w-8xl mr-16">
            <div class="register p-16 shadow" style="background-color:#F8F8F8">
                <p class="text-3xl mb-10 leading-normal">Unten können Sie neue makros registrieren!</p>
                <form @submit.prevent="submitForm">  
                    <div class="form-control">
                        <label>Titel:</label>
                        <input type="text" autocomplete="off" v-model="makro.title">
                    </div>
                    <div class="border-2 cursor-pointer border-grey-400">
                        <editor
                            apiKey="8spqxq12m8z598gogaydst2muefa6ygx32kigzgt05bazbbp"
                            placeholder="Antwort hier schreiben"
                            :initial-value="makro.content"
                            :value="makro.content"
                            @input="updateValue($event)"
                            :init="{
                            height: 324,
                            menubar: false,
                            branding: false,
                            toolbar_location: 'bottom',
                            resize: false,
                            skin: 'naked',
                            statusbar: false,
                            plugins: [
                            'advlist autolink lists link preview anchor',
                            'code fullscreen',
                            'paste code',
                            ],
                            toolbar:
                            'undo redo formatselect link bold italic backcolor forecolor \
                                alignleft aligncenter alignright alignjustify \
                                bullist numlist outdent indent \
                                fullscreen',
                            }"
                        >
                        </editor>
                    </div>
                    <button type="submit" class="mt-10 primary-btn">Makro hinzufügen</button>
                </form>
            </div>
        </div>
        <div class="flex-1 ml-16">
            <div class="makros shadow p-16" style="background-color:#F8F8F8">
                <DeleteModal message="Wollen Sie dieses Makro wirklich löschen?" v-if="deleteShownModal" @onCancel="closeDeleteModal" @onDelete="onDelete"/>
                <p class="text-3xl mb-10">Hier ist eine Liste mit allen Makros</p>
                <div class="table-users my-6" style="background-color:#F8F8F8">
                    <table class="min-w-max w-full table-auto">
                        <thead>
                            <tr
                                class="text-white uppercase text-2xl leading-normal"
                            >
                                <th class="py-5 px-10 text-left">Titel</th>
                                <th class="py-5 px-10 text-left">Aktionen</th>
                            </tr>
                        </thead>
                        <tbody class="text-gray-600 text-sm font-light">
                            <tr v-for="item in makros" :key="item._id" class="border-b border-gray-200 hover:bg-gray-100">
                                <td class="py-5 px-10 text-left whitespace-nowrap">
                                    <span class="font-medium text-2xl">{{ item.title }}</span>
                                </td>
                                <td class="py-5 px-10 text-left">
                                    <button class="button py-3 px-5 bg-red-600 text-white text-2xl hover:bg-red-400 mr-3" @click.stop="deleteMakro(item._id)">
                                        <i class="fas fa-times"></i>
                                    </button>
                                    <router-link :to="`/makros/edit/${item._id}`" class="button py-3 px-5 bg-yellow-400 text-white text-2xl hover:bg-yellow-200 mr-3">
                                        <i class="fas fa-pencil-alt"></i>
                                    </router-link>
                                    <router-link :to="`/makros/${item._id}`" class="button py-3 px-5 bg-blue-400 text-white text-2xl hover:bg-blue-200">
                                        <i class="fas fa-eye"></i>
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
  thead {
    tr {
      background: #4F98C1;
    }
  }
  @media screen and (max-width: 576px) {
    .makros {
        flex-direction: column;
        div:first-child {
            margin-right: 0px;
        }
        div:last-child {
            margin-top: 15px;
            margin-left: 0px;
        }
         .table-users {
            overflow-x: auto;
        }
    }
  }
</style>

<script>
    import DeleteModal from '@/components/DeleteModal.vue';
    import Editor from '@tinymce/tinymce-vue';
    import { mapActions, mapGetters } from 'vuex';

    export default {
        data() {
            return {
                deleteShownModal: false,
                deletion_id: '',
                makro: {
                    title: '',
                    content: ''
                }
            }
        },
        mounted() {
            this.getMakros();
        },
        components: {
            DeleteModal,
            editor: Editor
        },
        methods: {
            ...mapActions(['addMakro', 'getMakros', 'removeMakro']),
            submitForm() {
                this.addMakro(this.makro);
                this.makro.title = '';
                this.makro.content = '';
            },
            onDelete() {
                this.removeMakro(this.deletion_id);
                this.deletion_id = '';
                this.deleteShownModal = false;
            },
            closeDeleteModal() {
                this.deleteShownModal = false;
            },
            deleteMakro(id) {
                this.deleteShownModal = true;
                this.deletion_id = id;
            },
            updateValue(value) {
                this.makro.content = value;
            }
        },
        computed: {
            ...mapGetters(['makros'])
        }
    }
</script>