var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"makros flex"},[_c('div',{staticClass:"max-w-8xl mr-16"},[_c('div',{staticClass:"register p-16 shadow",staticStyle:{"background-color":"#F8F8F8"}},[_c('p',{staticClass:"text-3xl mb-10 leading-normal"},[_vm._v("Unten können Sie neue makros registrieren!")]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',{staticClass:"form-control"},[_c('label',[_vm._v("Titel:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.makro.title),expression:"makro.title"}],attrs:{"type":"text","autocomplete":"off"},domProps:{"value":(_vm.makro.title)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.makro, "title", $event.target.value)}}})]),_c('div',{staticClass:"border-2 cursor-pointer border-grey-400"},[_c('editor',{attrs:{"apiKey":"8spqxq12m8z598gogaydst2muefa6ygx32kigzgt05bazbbp","placeholder":"Antwort hier schreiben","initial-value":_vm.makro.content,"value":_vm.makro.content,"init":{
                        height: 324,
                        menubar: false,
                        branding: false,
                        toolbar_location: 'bottom',
                        resize: false,
                        skin: 'naked',
                        statusbar: false,
                        plugins: [
                        'advlist autolink lists link preview anchor',
                        'code fullscreen',
                        'paste code',
                        ],
                        toolbar:
                        'undo redo formatselect link bold italic backcolor forecolor \
                            alignleft aligncenter alignright alignjustify \
                            bullist numlist outdent indent \
                            fullscreen',
                        }},on:{"input":function($event){return _vm.updateValue($event)}}})],1),_c('button',{staticClass:"mt-10 primary-btn",attrs:{"type":"submit"}},[_vm._v("Makro hinzufügen")])])])]),_c('div',{staticClass:"flex-1 ml-16"},[_c('div',{staticClass:"makros shadow p-16",staticStyle:{"background-color":"#F8F8F8"}},[(_vm.deleteShownModal)?_c('DeleteModal',{attrs:{"message":"Wollen Sie dieses Makro wirklich löschen?"},on:{"onCancel":_vm.closeDeleteModal,"onDelete":_vm.onDelete}}):_vm._e(),_c('p',{staticClass:"text-3xl mb-10"},[_vm._v("Hier ist eine Liste mit allen Makros")]),_c('div',{staticClass:"table-users my-6",staticStyle:{"background-color":"#F8F8F8"}},[_c('table',{staticClass:"min-w-max w-full table-auto"},[_vm._m(0),_c('tbody',{staticClass:"text-gray-600 text-sm font-light"},_vm._l((_vm.makros),function(item){return _c('tr',{key:item._id,staticClass:"border-b border-gray-200 hover:bg-gray-100"},[_c('td',{staticClass:"py-5 px-10 text-left whitespace-nowrap"},[_c('span',{staticClass:"font-medium text-2xl"},[_vm._v(_vm._s(item.title))])]),_c('td',{staticClass:"py-5 px-10 text-left"},[_c('button',{staticClass:"button py-3 px-5 bg-red-600 text-white text-2xl hover:bg-red-400 mr-3",on:{"click":function($event){$event.stopPropagation();return _vm.deleteMakro(item._id)}}},[_c('i',{staticClass:"fas fa-times"})]),_c('router-link',{staticClass:"button py-3 px-5 bg-yellow-400 text-white text-2xl hover:bg-yellow-200 mr-3",attrs:{"to":`/makros/edit/${item._id}`}},[_c('i',{staticClass:"fas fa-pencil-alt"})]),_c('router-link',{staticClass:"button py-3 px-5 bg-blue-400 text-white text-2xl hover:bg-blue-200",attrs:{"to":`/makros/${item._id}`}},[_c('i',{staticClass:"fas fa-eye"})])],1)])}),0)])])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',{staticClass:"text-white uppercase text-2xl leading-normal"},[_c('th',{staticClass:"py-5 px-10 text-left"},[_vm._v("Titel")]),_c('th',{staticClass:"py-5 px-10 text-left"},[_vm._v("Aktionen")])])])
}]

export { render, staticRenderFns }